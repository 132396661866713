import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import React, { Fragment, useEffect, useState } from 'react';

import { resetPassword } from '../../api/auth';

import s from './index.module.scss';

import Seo from '../../components/Seo';
import Input from '../../components/input';
import Button from '../../components/button';
import validations from '../../utils/validations';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { checkUser } from '../../api/auth';

const PasswordReset = () => {
  const { handleSubmit, getValues, setValue, register, errors } = useForm();

  const [loading, setLoading] = useState(true);
  const [err, setError] = useState({
    status: false,
    msg: '',
  });

  useEffect(() => {
    (async () => {
      const user = await checkUser();
      if (!user) {
        return navigate('/login');
      }

      setLoading(false);
    })();
  }, []);

  const onSubmit = ({ old_password, new_password }) => {
    setLoading(true);
    resetPassword(old_password, new_password)
      .then(() => {
        setLoading(false);
        navigate('/profile');
      })
      .catch(({ code }) => {
        setValue('old_password', '');
        setValue('new_password', '');
        setValue('new_password_confirm', '');
        setLoading(false);

        switch (code) {
          case 'auth/wrong-password':
            return setError({
              status: true,
              msg:
                'La contraseña no es válida o el usuario no tiene contraseña.',
            });

          default:
            navigate('/404');
        }
      });
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Reestablece tu contraseña de inicio de sesión en coronasunsetchile.com"
        title="Cambiar contraseña"
      />

      <Header title="Cambiar Contraseña" />

      <div className={s.container}>
        <form onSubmit={handleSubmit(onSubmit)} className={s.inputsContainer}>
          <h1>Cambiar contraseña</h1>

          <Input
            width="30%"
            type="password"
            name="old_password"
            className="field"
            placeholder="Contraseña Actual"
            err={errors.old_password}
            referencia={register(validations.password)}
          />

          <Input
            width="30%"
            type="password"
            name="new_password"
            className="field"
            placeholder="Contraseña nueva"
            err={errors.new_password}
            referencia={register({
              ...validations.password,
              validate: (value) => {
                if (value !== getValues().old_password) {
                  return true;
                }
                return 'Ingrese una contraseña distina';
              },
            })}
          />

          <Input
            width="30%"
            type="password"
            name="new_password_confirm"
            className="field"
            placeholder="Confirmar contraseña"
            err={errors.new_password_confirm}
            referencia={register({
              required: 'Contraseña inválida',
              validate: (value) => {
                if (value === getValues().new_password) {
                  return true;
                }
                return 'Las contraseñas deben coincidir';
              },
            })}
          />

          {err.status && <div className={s.err}>{err.msg}</div>}

          <div className={s.btnContainer}>
            <Button
              type="submit"
              className="auth"
              value="Cambiar Contraseña"
              loading={loading}
              styles={{
                background: '#ffc72c',
                minWidth: '148px',
              }}
            />
          </div>
        </form>
      </div>

      <Footer />
    </Fragment>
  );
};

export default PasswordReset;
